import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/build/repo/web2/gatsby-starter-blog/src/components/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "626px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/73ba002b4f8dcaa0ea46fa3b1c53d901/af590/before-after_1.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "51%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAYAAAC0VX7mAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAB/ElEQVQoz32Qz4oTQRDG5ym8ePHowYsPYEQQvLjgwYMK/nkPDet4WNzNxZOvoC+g6EEEXV1cdCGCyWWDOxuSbP5M0jWdzcx011clPYmag9hQzFD166/r+yJDdM0Q7VCWbQ5H47g3GMS9/iAeTyaxIXpsiLYM0TlDdN4QPQ29NE3jfr8f93r9OJ2kgds0RNuG6HKUWfs8s1btfK79wUB/HiV6lCQ6HI2qXphl1l7NrL1ZcdZqSka7ge12NSVa57YiANsiogLJVcSralUi4kSkDDNmvgTgesWpljyeORjrkc09D1MnqnmYAXgYCaShy+NUA68STvVVQRgAqMH7jfDPJxPOP3yT8lNTyt2mLD4eiE8GvuKY65GoNIShMl14LArxyCt1z4sgitXLNbiloO+ecLH3XczXA51+2ddi/4e4w+OloOd6BNWG5l6Tt+990non7fFL7Yxfa+vkhfTMXrUhM9cA3lhZZrSPJdl9o53Pr5SbndD1snRSDxlWlh1lriwyKZhk4SaSu5kUnlaWgyD+CIY4itah5M32Kpoq82CnHonIzjIyLcPdtWL9DQbLQTDcZnhMiTElgObAjCClK1dZPwobPqte/k8BuALgxmpDFc+K04XCnqo4t849iZj5IoAHAG4BuP2Puuu9P8PMZwHcq3oqd6D6t5bcfWa+8AsEe9pkTJCvrgAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Brand design",
            "title": "Brand design",
            "src": "/static/73ba002b4f8dcaa0ea46fa3b1c53d901/af590/before-after_1.png",
            "srcSet": ["/static/73ba002b4f8dcaa0ea46fa3b1c53d901/772e8/before-after_1.png 200w", "/static/73ba002b4f8dcaa0ea46fa3b1c53d901/e17e5/before-after_1.png 400w", "/static/73ba002b4f8dcaa0ea46fa3b1c53d901/af590/before-after_1.png 626w"],
            "sizes": "(max-width: 626px) 100vw, 626px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <div className="row my-5">
      <div className="col-md-6">
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "800px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/764738c0f926a5e5c93705ed8c95523b/bcec7/mosflor-02.jpg",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "100%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAUABQDASIAAhEBAxEB/8QAGQABAAIDAAAAAAAAAAAAAAAAAAQFAQID/8QAFQEBAQAAAAAAAAAAAAAAAAAAAAL/2gAMAwEAAhADEAAAAZVZY7QsHRSLkhKFv//EABsQAAMAAgMAAAAAAAAAAAAAAAECAwAEEyEx/9oACAEBAAEFAtxyh5XyXctyjo8nJkPLQWpWKquf/8QAFBEBAAAAAAAAAAAAAAAAAAAAIP/aAAgBAwEBPwEf/8QAFxEBAQEBAAAAAAAAAAAAAAAAAhASMf/aAAgBAgEBPwFLMfJ//8QAHhAAAgIBBQEAAAAAAAAAAAAAAAECEXEQEjEzQZH/2gAIAQEABj8CjTo7JfSOCO1+EW+cCE5WJK9P/8QAGxABAQEBAAMBAAAAAAAAAAAAAREAITFRYaH/2gAIAQEAAT8hvfQ+HXvDEpWqe4wgfA6HajvG/Nmo5Jxz9Qe8EJv/2gAMAwEAAgADAAAAECsngv/EABQRAQAAAAAAAAAAAAAAAAAAACD/2gAIAQMBAT8QH//EABgRAQEBAQEAAAAAAAAAAAAAAAEAESEx/9oACAECAQE/EDOsOmxHqPL/xAAeEAEAAgIBBQAAAAAAAAAAAAABABExYVEhQXGx0f/aAAgBAQABPxA6CvWnc4ZSWgX+xRyFTatEuR6UbjeyOk4Vk6uo7bT1BRlgEGfEYoOixeb4hCMBU//Z')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "Brand design",
                "title": "Brand design",
                "src": "/static/764738c0f926a5e5c93705ed8c95523b/4b190/mosflor-02.jpg",
                "srcSet": ["/static/764738c0f926a5e5c93705ed8c95523b/e07e9/mosflor-02.jpg 200w", "/static/764738c0f926a5e5c93705ed8c95523b/066f9/mosflor-02.jpg 400w", "/static/764738c0f926a5e5c93705ed8c95523b/4b190/mosflor-02.jpg 800w", "/static/764738c0f926a5e5c93705ed8c95523b/bcec7/mosflor-02.jpg 1188w"],
                "sizes": "(max-width: 800px) 100vw, 800px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
      </div>
      <div className="col-md-6">
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "800px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/cbb6a1e1648690098c67a157fb4ad35a/bcec7/mosflor-04.jpg",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "100%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAUABQDASIAAhEBAxEB/8QAGQABAAIDAAAAAAAAAAAAAAAAAAEFAwQG/8QAFAEBAAAAAAAAAAAAAAAAAAAAAP/aAAwDAQACEAMQAAABttLNSnVAAkH/xAAbEAABBAMAAAAAAAAAAAAAAAADAgQREwABIP/aAAgBAQABBQI5aRje2Kx9uABXJuP/xAAUEQEAAAAAAAAAAAAAAAAAAAAg/9oACAEDAQE/AR//xAAUEQEAAAAAAAAAAAAAAAAAAAAg/9oACAECAQE/AR//xAAfEAAABQQDAAAAAAAAAAAAAAAAAQIRIRASMVIgIkH/2gAIAQEABj8CuZ5DWNO1MEc+hHRGdeP/xAAdEAACAgEFAAAAAAAAAAAAAAABEQAhMRAgUWFx/9oACAEBAAE/IfO5NRQmgXy0RHF0DhgF1x22/wD/2gAMAwEAAgADAAAAEMPPAP/EABQRAQAAAAAAAAAAAAAAAAAAACD/2gAIAQMBAT8QH//EABQRAQAAAAAAAAAAAAAAAAAAACD/2gAIAQIBAT8QH//EABwQAAICAgMAAAAAAAAAAAAAAAERITEAURAgYf/aAAgBAQABPxBZbD2HLR1hQ4hLIHJMBTwUBEIKjKzdS6CIUXfX/9k=')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "Brand design",
                "title": "Brand design",
                "src": "/static/cbb6a1e1648690098c67a157fb4ad35a/4b190/mosflor-04.jpg",
                "srcSet": ["/static/cbb6a1e1648690098c67a157fb4ad35a/e07e9/mosflor-04.jpg 200w", "/static/cbb6a1e1648690098c67a157fb4ad35a/066f9/mosflor-04.jpg 400w", "/static/cbb6a1e1648690098c67a157fb4ad35a/4b190/mosflor-04.jpg 800w", "/static/cbb6a1e1648690098c67a157fb4ad35a/bcec7/mosflor-04.jpg 1188w"],
                "sizes": "(max-width: 800px) 100vw, 800px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
      </div>
    </div>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "800px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/dfc1ead1b51c9423c76d42673fe259fd/bcec7/mosflor-03.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "100%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAUABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAAAAEDBf/EABUBAQEAAAAAAAAAAAAAAAAAAAAB/9oADAMBAAIQAxAAAAHvy5rqhAKD/8QAGRAAAQUAAAAAAAAAAAAAAAAAAAEQESAx/9oACAEBAAEFAiXTaf/EABQRAQAAAAAAAAAAAAAAAAAAACD/2gAIAQMBAT8BH//EABQRAQAAAAAAAAAAAAAAAAAAACD/2gAIAQIBAT8BH//EABQQAQAAAAAAAAAAAAAAAAAAADD/2gAIAQEABj8CH//EABoQAAICAwAAAAAAAAAAAAAAAAABEDERIEH/2gAIAQEAAT8hMH2HTFdtf//aAAwDAQACAAMAAAAQwM8A/8QAFREBAQAAAAAAAAAAAAAAAAAAASD/2gAIAQMBAT8QASP/xAAUEQEAAAAAAAAAAAAAAAAAAAAg/9oACAECAQE/EB//xAAbEAEAAgIDAAAAAAAAAAAAAAABETEAIRAgcf/aAAgBAQABPxB0YKgC+8ESaSM2srmuv//Z')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Brand design",
            "title": "Brand design",
            "src": "/static/dfc1ead1b51c9423c76d42673fe259fd/4b190/mosflor-03.jpg",
            "srcSet": ["/static/dfc1ead1b51c9423c76d42673fe259fd/e07e9/mosflor-03.jpg 200w", "/static/dfc1ead1b51c9423c76d42673fe259fd/066f9/mosflor-03.jpg 400w", "/static/dfc1ead1b51c9423c76d42673fe259fd/4b190/mosflor-03.jpg 800w", "/static/dfc1ead1b51c9423c76d42673fe259fd/bcec7/mosflor-03.jpg 1188w"],
            "sizes": "(max-width: 800px) 100vw, 800px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "800px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/aa9507f8174ad57e4b8aa287ec397f80/bcec7/mosflor-05.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "100%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAUABQDASIAAhEBAxEB/8QAGAABAAMBAAAAAAAAAAAAAAAAAAECBQT/xAAUAQEAAAAAAAAAAAAAAAAAAAAA/9oADAMBAAIQAxAAAAHXpz0NZAsAD//EABsQAAIDAAMAAAAAAAAAAAAAAAIDAAEEEBEx/9oACAEBAAEFAmuFUZopdTYJFNfbRrzn/8QAFBEBAAAAAAAAAAAAAAAAAAAAIP/aAAgBAwEBPwEf/8QAFBEBAAAAAAAAAAAAAAAAAAAAIP/aAAgBAgEBPwEf/8QAHhAAAQQBBQAAAAAAAAAAAAAAAgABERIQICEiUZH/2gAIAQEABj8Ca079LmJNgKjKGoF4m0f/xAAeEAEBAAIABwAAAAAAAAAAAAABEQAhIDFBUWFx0f/aAAgBAQABPyHUi6IwMjPg+4NB747TNsLOWEpqNd819XB//9oADAMBAAIAAwAAABDAAAD/xAAUEQEAAAAAAAAAAAAAAAAAAAAg/9oACAEDAQE/EB//xAAUEQEAAAAAAAAAAAAAAAAAAAAg/9oACAECAQE/EB//xAAeEAEAAgIBBQAAAAAAAAAAAAABESEAMWEgQVGB0f/aAAgBAQABPxDu9tB1HPOaJosl+KwRmhJl82kdW+MeciggSMKJIQSeuj//2Q==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Brand design",
            "title": "Brand design",
            "src": "/static/aa9507f8174ad57e4b8aa287ec397f80/4b190/mosflor-05.jpg",
            "srcSet": ["/static/aa9507f8174ad57e4b8aa287ec397f80/e07e9/mosflor-05.jpg 200w", "/static/aa9507f8174ad57e4b8aa287ec397f80/066f9/mosflor-05.jpg 400w", "/static/aa9507f8174ad57e4b8aa287ec397f80/4b190/mosflor-05.jpg 800w", "/static/aa9507f8174ad57e4b8aa287ec397f80/bcec7/mosflor-05.jpg 1188w"],
            "sizes": "(max-width: 800px) 100vw, 800px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span>{`
`}<span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "800px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/b263bfb6bc5cd3ccb44d2505069ca08b/bcec7/mosflor-06.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "100%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAUABQDASIAAhEBAxEB/8QAGgABAAIDAQAAAAAAAAAAAAAAAAMFAQIEBv/EABYBAQEBAAAAAAAAAAAAAAAAAAACBP/aAAwDAQACEAMQAAABnj44Y2WSqJ9RqVnyD//EABwQAAICAgMAAAAAAAAAAAAAAAADERQBAgQSIf/aAAgBAQABBQLblM7W2FthPsZnMldRWUVlH//EABoRAAIDAQEAAAAAAAAAAAAAAAABAhJRIjH/2gAIAQMBAT8BpFLw5wsy0tP/xAAUEQEAAAAAAAAAAAAAAAAAAAAg/9oACAECAQE/AR//xAAcEAACAQUBAAAAAAAAAAAAAAAAATIQESExQqH/2gAIAQEABj8CdksHJyOmiJH0if/EABwQAQACAgMBAAAAAAAAAAAAAAEAMRFRIYHwof/aAAgBAQABPyEghElTd8TVl1F7MsaEGdosq83m2e6nms//2gAMAwEAAgADAAAAEAQngv/EABoRAAICAwAAAAAAAAAAAAAAAAARATEhYZH/2gAIAQMBAT8QQWEOO5Nzp//EABgRAAMBAQAAAAAAAAAAAAAAAAABESFR/9oACAECAQE/EK2b0iIj/8QAHRAAAgICAwEAAAAAAAAAAAAAAREAITFhgdHwQf/aAAgBAQABPxC6sdItHOYGBgH0v3LDQeX7h5CWIybdxiQbONQdSHiC6sJIFnmWv05mj63P/9k=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Brand design",
            "title": "Brand design",
            "src": "/static/b263bfb6bc5cd3ccb44d2505069ca08b/4b190/mosflor-06.jpg",
            "srcSet": ["/static/b263bfb6bc5cd3ccb44d2505069ca08b/e07e9/mosflor-06.jpg 200w", "/static/b263bfb6bc5cd3ccb44d2505069ca08b/066f9/mosflor-06.jpg 400w", "/static/b263bfb6bc5cd3ccb44d2505069ca08b/4b190/mosflor-06.jpg 800w", "/static/b263bfb6bc5cd3ccb44d2505069ca08b/bcec7/mosflor-06.jpg 1188w"],
            "sizes": "(max-width: 800px) 100vw, 800px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span>{`
`}<span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "800px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/4041377944d8b1d53cb9a9df88c847ea/bcec7/mosflor-07.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "100%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAUABQDASIAAhEBAxEB/8QAGQABAAIDAAAAAAAAAAAAAAAAAAMEAQIF/8QAFgEBAQEAAAAAAAAAAAAAAAAAAQID/9oADAMBAAIQAxAAAAG9pdlNOaupMQgRiK//xAAYEAADAQEAAAAAAAAAAAAAAAAAAQIREP/aAAgBAQABBQLmMUSYYyqwd1z/xAAYEQACAwAAAAAAAAAAAAAAAAAAARAREv/aAAgBAwEBPwHZYo//xAAYEQACAwAAAAAAAAAAAAAAAAAAAhAREv/aAAgBAgEBPwGjI0f/xAAZEAADAAMAAAAAAAAAAAAAAAAAEDEBITL/2gAIAQEABj8CUycmiqr/xAAcEAEBAAEFAQAAAAAAAAAAAAABABEhMUFRcZH/2gAIAQEAAT8hxZOyG2+V0T7rYBw8nPGegSJld2//2gAMAwEAAgADAAAAEAfYw//EABcRAQEBAQAAAAAAAAAAAAAAAAEQEUH/2gAIAQMBAT8Qzwkk/8QAFxEBAQEBAAAAAAAAAAAAAAAAAQAQEf/aAAgBAgEBPxCcyTP/xAAeEAEAAgEFAQEAAAAAAAAAAAABABExIUFRYXGRwf/aAAgBAQABPxDcJT2xrzPBqwe0nuBczl/qCoC+4CXFJ9ufRRgmR5pFFovbP//Z')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Brand design",
            "title": "Brand design",
            "src": "/static/4041377944d8b1d53cb9a9df88c847ea/4b190/mosflor-07.jpg",
            "srcSet": ["/static/4041377944d8b1d53cb9a9df88c847ea/e07e9/mosflor-07.jpg 200w", "/static/4041377944d8b1d53cb9a9df88c847ea/066f9/mosflor-07.jpg 400w", "/static/4041377944d8b1d53cb9a9df88c847ea/4b190/mosflor-07.jpg 800w", "/static/4041377944d8b1d53cb9a9df88c847ea/bcec7/mosflor-07.jpg 1188w"],
            "sizes": "(max-width: 800px) 100vw, 800px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      